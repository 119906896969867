import axios from "@/utils/request";

/**
 * 验证是否收藏
 * @param {*} config
 * @returns
 */
export function fetchCheckCollect(config) {
  return axios({
    url: "/book/isfavorites",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 * 加入收藏&& 取消收藏
 * @param {*} config
 * @returns
 */
export function fetchJoinOrCancelCollect(config) {
  return axios({
    url: "/book/favorites",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 *账号密码登录
 * @param {*} config
 * @returns
 */
export function fetchAccountLogin(config) {
  return axios({
    url: "/user/login",
    method: "post",
    ...config,
  });
}

/**
 * 手机验证码登录
 * @param {*} config
 * @param { } mobile
 * @param {} captcha
 * @returns
 */
export function fetchMobileLogin(config) {
  return axios({
    url: "/user/mobilelogin",
    method: "post",
    ...config,
  });
}

/**
 *发送短信
 * @param {*} config
 * @returns
 */
export function fetchSendCode(config) {
  return axios({
    url: "/sms/send",
    method: "post",
    ...config,
  });
}

/**
 * 设置密码
 * @param {*} config
 * @returns
 */
export function fetchSetPassword(config) {
  return axios({
    url: "/user/setpassword",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 * 修改手机号
 * @param config
 * @returns {*}
 */
export function fetchModifyMobile(config) {
  return axios({
    url: "/user/modifymobile",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 * 获取用户信息
 * @param {*} config
 * @returns
 */
export function fetchUserInfo(config) {
  return axios({
    url: "/user/user",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 * 用户收藏
 * @param config
 * @returns {*}
 */
export function fetchUserCollect(config) {
  return axios({
    url: "/user/myfavorites",
    method: "post",
    isToken: true,
    ...config,
  });
}
/**
 * 修改个人资料
 * @param {*} config
 * @returns
 */
export function fetchModifyUser(config) {
  return axios({
    url: "/user/profile",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 *验证IP归属机构
 * @param {*} config
 * @returns
 */
export function fetchAuthIP(config) {
  return axios({
    url: "/user/authip",
    method: "post",
    ...config,
  });
}

/**
 * 校验授权码
 * @param {*} config
 * @returns
 */
export function fetchCheckcode(config) {
  return axios({
    url: "/user/authcode",
    method: "post",
    isToken: true,
    isLoading: true,
    isMsg: true,
    ...config,
  });
}

/**
 * 无验证码注册
 * @param {*} config
 * @returns
 */
export function fetchRegister_(config) {
  return axios({
    url: "/user/register_n",
    method: "post",
    isToken: true,
    isLoading: true,
    isMsg: true,
    ...config,
  });
}

/**
 * 登录机构用户信息
 */
 export function fetchUserRegorg(config) {
  return axios({
    url: "/user/regorg",
    method: "post",
    // isToken: true,
    isLoading: true,
    isMsg: true,
    ...config,
  });
}

