import { TOKEN } from "@/constant/storage";
import axios from "axios";
import { getItem } from "./storage";
import router from "@/router/index";
import { Toast } from "@nutui/nutui";

let test = "https://new.yibo365.net/api";
const http = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  // baseURL: test,
  timeout: 300 * 1000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

let count = 0;
let isOut = false;

function handRequest() {
  count++;
}

function handResponse() {
  //
  count--;
  if(count==0){
    isOut = false;
  }
}

http.interceptors.request.use(
  (config) => {
    if (config.isToken) {
      !config.data && (config.data = {});
      config.data.token = getItem(TOKEN);
      config.headers.HTTP_TOKEN = getItem(TOKEN);
    }
    handRequest();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    handResponse();
    const { status } = response;
    if (status >= 200 && status < 300) {
      //
      if (response.data.code == 1) {
        return response.data;
      } else if (response.data.code == 0) {
        response.data.msg && Toast.text(response.data.msg);
        return Promise.reject(response.data);
      } else {
        return response.data;
      }
    } else {
      console.log("ddd", response);
      return Promise.reject(response.data);
    }
    return response;
  },
  (error) => {
    handResponse();
    // console.log("error///////", error.response);
    if (error.response.data.code == 401) {
      //
      if(isOut){
        return Promise.reject(error)
      }
      // console.log("router", router.currentRoute);
      let redirect = router.currentRoute.fullPath;
      isOut = true;
      localStorage.clear();
      router.replace({
        path: "/login",
        query: {
          redirect: redirect,
        },
      });
    }
    return Promise.reject(error);
  }
);

export default http;
