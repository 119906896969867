import Vue from "vue";
import App from "./App.vue";
import VueRouter from 'vue-router'

import "normalize.css";
import "../nutUI.config";
// import NutUI from '@nutui/nutui';
// import '@nutui/nutui/dist/nutui.css';
// NutUI.install(Vue);

import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.config.productionTip = false;
import router from "./router";
Vue.use(VueRouter)
new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount("#app");
