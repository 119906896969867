import { defineStore } from "pinia";
import { fetchBanner, fetchLibConfig, fetchToplist } from "@/api/common.api";
import home_icon from "@/assets/image-icons/home_icon.png";
import home_on_icon from "@/assets/image-icons/home_on_icon.png";
import books_icon from "@/assets/image-icons/books_icon.png";
import books_on_icon from "@/assets/image-icons/books_on_icon.png";
import journal_icon from "@/assets/image-icons/journal_icon.png";
import journal_on_icon from "@/assets/image-icons/journal_on_icon.png";
import voiceBook_icon from "@/assets/image-icons/voice-book_icon.png";
import voiceBook_on_icon from "@/assets/image-icons/voice-book_on_icon.png";
import newspaper_icon from "@/assets/image-icons/newspaper_icon.png";
import newspaper_on_icon from "@/assets/image-icons/newspaper_on_icon.png";
import { fetchUserInfo } from "@/api/user.api";
import { removeItem, setItem } from "@/utils/storage";
import { USER_INFO, LIB_CONFIG, TOKEN } from "@/constant/storage";
import { Toast } from "@nutui/nutui";
import router from "../router/index";

export const useMain = defineStore("main", {
  state: () => {
    return {
      tabList: [],
      menuAllConfig: {
        book: {
          tabTitle: "图书",
          curr: false,
          icon: books_icon,
          activeIcon: books_on_icon,
          url: "/home/books",
          key: "book",
        },
        journal: {
          tabTitle: "期刊",
          curr: false,
          icon: journal_icon,
          activeIcon: journal_on_icon,
          url: "/home/journal",
          key: "journal",
        },
        lbook: {
          tabTitle: "听书",
          curr: false,
          icon: voiceBook_icon,
          activeIcon: voiceBook_on_icon,
          url: "/home/voicebook",
          key: "lbook",
        },
        paper: {
          tabTitle: "报纸",
          curr: false,
          icon: newspaper_icon,
          activeIcon: newspaper_on_icon,
          url: "/home/newspaper",
          key: "paper",
        },
      },

      isShowPop: false,
      userinfo: {},
      rankMenus: [],
      curranRankMenu: "",
      menuList: [],

      // 首页banner
      banners: [],

      // keep-alive
      keepInclude: [
        "home-index",
        "voicebook",
        "search",
        "home-voicebook",
        "home",
      ],
    };
  },
  getters: {},
  actions: {
    async getLibConfig() {
      try {
        let res = await fetchLibConfig();
        setItem(LIB_CONFIG, res.data.menu);
        let keys = Object.keys(res.data.menu);
        let list = [
          {
            tabTitle: "首页",
            curr: true,
            icon: home_icon,
            activeIcon: home_on_icon,
            url: "/home/index",
          },
        ];
        const rules = {
          book: () => {
            //
            console.log("paper");
          },
          paper: () => {
            //
          },
          lbook: () => {
            //
          },
          journal: () => {
            //
          },
        };
        let rankmenus = [];
        console.log('bbb', keys)
        let arr=['qqfm','es']
        keys = keys.filter(item => !arr.includes(item))
        console.log('bb', keys)
        keys.forEach((item) => {
          // rules[item]();
          // console.log('bbbbb', item)
          list.push(this.menuAllConfig[item]);
          rankmenus.push(this.menuAllConfig[item]);
        });
        this.tabList = list;
        this.rankMenus = rankmenus
          .filter((item) => item.key != "paper")
          .map((item, index) => {
            item.name = item.tabTitle;
            item.active = index == 0;
            return item.key;
          });
        this.curranRankMenu = this.rankMenus[0];
        // console.log("config", this.tabList);
        this.menuList = list.slice(1).map((item) => item.key);

        // 根据配置设置banner

        this.getBanner();

        // 根据配置获取排行榜
        let toplist = await fetchToplist({
          data: {
            menu: this.rankMenus[0],
            ltype: "toplist",
          },
        });
        console.log("toplist", toplist);
        // router
      } catch (err) {
        console.error(err);
      }
    },

    async getUserInfo() {
      try {
        let res = await fetchUserInfo();
        this.userinfo = res.data.userinfo;
        setItem(USER_INFO, res.data.userinfo);
      } catch (err) {
        console.log("err", err);
      }
    },

    rankMenusClick(data) {
      this.rankMenus.forEach((item) => {
        item.active = item.key == data.key;
      });
    },

    async getBanner() {
      try {
        let res = await fetchBanner();
        // console.log("banner", res);  
        this.banners = res.data.filter((item) => this.menuList.includes(item.type));
      } catch (err) {}
    },

    // 校验机构是否过期
    async checkOgran() {
      try {
        let res = await fetchUserInfo();
        let userinfo = res.data.userinfo;
        if(userinfo.type==3){
          document.title = userinfo.nickname;
        }
        let expiresTime = res.data.userinfo.test_endtime * 1000;
        let nowTime = new Date().getTime();
        if ( res.data.userinfo.status!='normal' || expiresTime - nowTime <= 0) {
          removeItem(TOKEN);
          removeItem(USER_INFO);
          Toast.text('机构过期');
          router.push({
            path:'/login'
          });
        }
      } catch (err) {}
    },

    
  },
});
