<template>
  <div class="home">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link
        :to="{
          path: '/read/epub-local',
          query: { timestamp: new Date().getTime() },
        }"
        >epub-local</router-link
      >|
      <router-link to="/read/epub-online">epub-online</router-link>
    </nav>
    <img alt="Vue logo" src="../assets/logo.png" />
    <br />
    <router-link to="/main">main</router-link>

    <button @click="login">login</button>
  </div>
</template>

<script setup>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import { fetchLoginByAccount } from "@/api/login.api";
import { setItem } from "@/utils/storage";
import { USER, TOKEN } from "@/constant/storage";
import { useRouter } from "vue-router/composables";

const router = useRouter();
const login = () => {
  //

  router.push({
    path: "/login",
  });

  return;
  fetchLoginByAccount({
    data: {
      account: "18326360002",
      password: "yl123456",
    },
  })
    .then((res) => {
      //
      console.log("res", res);
      setItem(USER, res.data.userinfo);
      setItem(TOKEN, res.data.userinfo.token);
      router.push({
        path: "/main/index",
      });
    })
    .catch((err) => {
      //
      console.error("err", err);
    });
};
</script>
