import { Toast } from "@nutui/nutui";

let toast = null;
export function showToast(config) {
  toast = Toast.loading(config);
}

export function hideToast() {
  toast && toast.hide();
}

/**
 * 将秒转成时分秒格式
 * @param {*} value
 * @returns
 */
export function secondToms(value) {
  const MINUTE = 60;
  console.log("ccc", value);
  if (value == 0) {
    return {
      minute: 0,
      second: 0,
    };
  }
  value = Math.floor(value);
  let minute = Math.floor(value / MINUTE);
  let second = value % MINUTE;
  if (value >= MINUTE) {
    return {
      minute,
      second,
    };
  } else {
    minute = 0;
    return {
      minute,
      second,
    };
  }
}

/**
 * 补零
 * @param {*} value
 * @returns
 */
export function padZero(value) {
  if (value < 10) {
    value = `0${value}`;
  }
  return value;
}

/**
 * 本地环境
 */
export function hasDevelopment() {
  let mode = process.env.VUE_APP_MODE;
  return mode == "dev";
}
/**
 * 测试环境
 */
export function hasDev(fn) {
  if (process.env.VUE_APP_MODE == "dev") {
    fn();
  }
}

/**
 * 是否是移动端
 * @returns
 */
export function hasMobileDevice() {
  var uA = navigator.userAgent.toLowerCase();
  var ipad = uA.match(/ipad/i) == "ipad";
  var iphone = uA.match(/iphone os/i) == "iphone os";
  var midp = uA.match(/midp/i) == "midp";
  var uc7 = uA.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var uc = uA.match(/ucweb/i) == "ucweb";
  var android = uA.match(/android/i) == "android";
  var windowsce = uA.match(/windows ce/i) == "windows ce";
  var windowsmd = uA.match(/windows mobile/i) == "windows mobile";
  if (
    !(ipad || iphone || midp || uc7 || uc || android || windowsce || windowsmd)
  ) {
    // PC 端
    return false;
  } else {
    // 移动端
    return true;
  }
}

/**
 * 是否是pc端
 * @returns
 */
export function hasPcDevice() {
  var uA = navigator.userAgent.toLowerCase();
  var ipad = uA.match(/ipad/i) == "ipad";
  var iphone = uA.match(/iphone os/i) == "iphone os";
  var midp = uA.match(/midp/i) == "midp";
  var uc7 = uA.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var uc = uA.match(/ucweb/i) == "ucweb";
  var android = uA.match(/android/i) == "android";
  var windowsce = uA.match(/windows ce/i) == "windows ce";
  var windowsmd = uA.match(/windows mobile/i) == "windows mobile";
  if (
    !(ipad || iphone || midp || uc7 || uc || android || windowsce || windowsmd)
  ) {
    // PC 端
    return true;
  } else {
    // 移动端
    return false;
  }
}

/**
 * 新窗口打卡
 * @param {*} href
 */
export function tapOpen(href, target = "_self") {
  let link_el = document.createElement("a");
  link_el.style.display = "none";
  link_el.target = target;
  link_el.href = href;
  document.body.appendChild(link_el);
  link_el.click();
  document.body.removeChild(link_el);
}

/**
 * 获取浏览器名称
 * @returns
 */
export function getBrowser() {
  let ua = window.navigator.userAgent;
  function check360() {
    var result = false;
    for (var key in navigator.plugins) {
      // np-mswmp.dll只在360浏览器下存在
      if (navigator.plugins[key].filename == "internal-nacl-plugin") {
        return !result;
      }
    }
    return result;
  }
  if (/qqbrowser/gi.test(ua)) {
    return `QQBrowser`;
  } else if (/Wechat/gi.test(ua)) {
    return `wechatBrowser`;
  } else if (/edg/gi.test(ua)) {
    return `edge`;
  } else if (/Firefox/gi.test(ua)) {
    return `Firefox`;
  } else if (/SE 2.X/gi.test(ua)) {
    return "sougou";
  } else if (check360()) {
    return "360Browser";
  } else if (/chrome/gi.test(ua) && /safari/gi.test(ua)) {
    return "chrome";
  }
}

/**
 * 获取系统类型
 * @returns
 */
export function getOsInfo() {
  let ua = window.navigator.userAgent;
  if (/iphone/gi.test(ua) && /mac/gi.test(ua)) {
    return "ios";
  } else if (/mac/gi.test(ua)) {
    return `mac`;
  } else if (/android/gi.test(ua)) {
    return "android";
  } else if (/windows/gi.test(ua)) {
    return "windows";
  }
}
