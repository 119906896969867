import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

import { useMain } from "@/stores/main";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/login",
    component: HomeView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  {
    path: "/read/epub-local",
    name: "epub-local",
    component: () => import("@/views/read/epub-local.vue"),
  },
  {
    path: "/read/epub-online",
    name: "epub-online",
    component: () => import("@/views/read/epub-online.vue"),
  },
  {
    path: "/read/pdf-online",
    name: "pdf-online",
    component: () => import("@/views/read/pdf-online.vue"),
  },
  {
    path: "/home",
    name: "home_",
    redirect: "/home/index",
    component: () => import("@/views/home/home.vue"),
    children: [
      {
        path: "/home/index",
        name: "home-index",
        component: () => import("@/views/home/pages/index/index.vue"),
        meta: {
          top: 0,
        },
      },
      {
        path: "/home/books",
        name: "home-books",
        component: () => import("@/views/home/pages/books/books.vue"),
        meta: {
          top: 0,
        },
      },
      {
        path: "/home/journal",
        name: "home-journal",
        component: () => import("@/views/home/pages/journal/journal.vue"),
        meta: {
          top: 0,
        },
      },
      {
        path: "/home/voicebook",
        name: "home-voicebook",
        component: () => import("@/views/home/pages/voicebook/voicebook.vue"),
        meta: {
          top: 0,
        },
      },
      {
        path: "/home/newspaper",
        name: "hoem-newspaper",
        component: () => import("@/views/home/pages/newspaper/newspaper.vue"),
        meta: {
          top: 0,
        },
      },
    ],
  },
  {
    path: "/userinfo",
    name: "userinfo",
    component: () => import("@/views/mine/userinfo/userinfo.vue"),
  },

  {
    path: "/books-detail/:id",
    props: (route) => ({ id: route.params.id }),
    name: "books-detail",
    component: () => import("@/views/home/pages/books/books-detail.vue"),
  },
  {
    path: "/hot-books",
    name: "hot-books",
    component: () => import("@/views/home/pages/books/hot-book-list.vue"),
  },
  {
    path: "/journal-detail/:brandId/:id",
    name: "journal-detail",
    props: (route) => ({ brandId: route.params.brandId, id: route.params.id }),
    component: () => import("@/views/home/pages/journal/journal-detail.vue"),
  },
  {
    path: "/past-journal-list/:brandId",
    name: "past-journal-list",
    props: (route) => ({ brandId: route.params.brandId }),
    component: () => import("@/views/home/pages/journal/past-journal-list.vue"),
  },
  {
    path: "/lbook-detail/:id",
    name: "lbook-detail",
    props: (route) => ({ id: route.params.id }),
    component: () => import("@/views/home/pages/voicebook/lbook-detail.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/search/search.vue"),
  },
  {
    path: "/qqfm",
    name: "qqfm",
    component: () => import("@/views/qqFM/qqfm.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("@/views/login/forget-password.vue"),
  },
  {
    path: "/bind-mobile",
    name: "bind-mobile",
    component: () => import("@/views/login/bind-mobile.vue"),
  },
  {
    path: "/set-password",
    name: "set-password",
    component: () => import("@/views/login/set-password"),
  },
  {
    path: "/my-collect",
    name: "my-collect",
    component: () => import("@/views/mine/my-collect.vue"),
    beforeEnter: (to, from, next) => {
      // ...
      const mainStore = useMain();
      let unIncludes = ["books-detail", "journal-detail", "lbook-detail"];
      !mainStore.keepInclude.includes(to.name) &&
        mainStore.keepInclude.push(to.name);
      next();
    },
  },
  {
    path: "/journal-read",
    name: "journal-read",
    component: () => import("@/views/home/pages/journal/journal-read.vue"),
  },
  {
    path: "/modify-mobile",
    name: "modify-mobile",
    component: () => import("@/views/mine/modify-mobile.vue"),
  },
  {
    path: "/verify-mobile",
    name: "verify-mobile",
    component: () => import("@/views/mine/verify-mobile.vue"),
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("@/views/mine/feedback.vue"),
  },
  {
    path: "/sign",
    name: "sign",
    component: () => import("@/views/login/sign.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/mine/about.vue"),
  },
  {
    path: "/login_opac",
    name: "login-opac",
    component: () => import("@/views/login/login-opac.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log("to", to.meta.top);
    console.log("from", from);
    console.log("savedPosition", savedPosition);

    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.meta.top) {
        return { y: to.meta.top };
      }
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  console.log("beforeeach===========", to);
  const mainStore = useMain();
  if (to.name.includes("home")) {
    if (!mainStore.keepInclude.includes("home")) {
      mainStore.keepInclude.push("home");
    }
  }
  if (to.name == "login") {
    mainStore.keepInclude = mainStore.keepInclude.filter(
      (item) => item != "home"
    );
  }
  //
  next();
});
router.afterEach((to, from) => {
  // console.log('after-each-----',to);
});

export default router;
