<template>
  <div id="app">
    <keep-alive :include="keepInclude">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script setup>
import { computed, onBeforeUpdate } from "vue";
import { useMain } from "@/stores/main";
import { useRoute } from "vue-router/composables";
import { hasPcDevice } from "@/utils/tools";

const mainStore = useMain();

const keepInclude = computed(() => {
  return mainStore.keepInclude;
});

const route = useRoute();

function handDevice() {
  // var uA = navigator.userAgent.toLowerCase();
  // var ipad = uA.match(/ipad/i) == "ipad";
  // var iphone = uA.match(/iphone os/i) == "iphone os";
  // var midp = uA.match(/midp/i) == "midp";
  // var uc7 = uA.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  // var uc = uA.match(/ucweb/i) == "ucweb";
  // var android = uA.match(/android/i) == "android";
  // var windowsce = uA.match(/windows ce/i) == "windows ce";
  // var windowsmd = uA.match(/windows mobile/i) == "windows mobile";
  // if (
  //   !(ipad || iphone || midp || uc7 || uc || android || windowsce || windowsmd)
  // ) {
  //   // PC 端
  //   console.log("pc");
  //   window.location.href = "http://pc.new.yibo365.net/login";
  // } else {
  //   // 移动端
  //   console.log("移动端");
  // }
  // let hrefurl = window.location.href.split("?");
  // console.log("-----------", hrefurl);

  if (hasPcDevice()) {
    let hrefurl = window.location.href.split("?");
    let baseUrl = `http://pc.yibo365.net/login`;
    if (hrefurl.length == 2) {
      window.location.href = baseUrl + "?" + hrefurl[1];
    } else {
      window.location.href = baseUrl;
    }
  }
}

handDevice();

onBeforeUpdate(() => {
  const list = [
    "login",
    "forget-password",
    "bind-mobile",
    "set-password",
    "sign",
    "verify-mobile",
    "login-opac",
    "epub-online",
    "pdf-online",
  ];
  console.log("beforeupdate", route.name);
  if (!list.includes(route.name)) {
    mainStore.checkOgran();
  }
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 16px;
  // width: 100vw;
  // height: 100vh;

  .text-over-1 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .text-over-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .text-over-3 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}
.nut-toast.nut-loading .nut-toast-icon.nut-toast-icon-rotate {
  position: static;
}
</style>
