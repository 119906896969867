import Vue from "vue";

import {
  Tabbar,
  // Swiper,
  Icon,
  Popup,
  Button,
  NavBar,
  Cell,
  TextInput,
  Uploader,
  SearchBar,
  Scroller,
  Infiniteloading,
  Toast,
  Slider,
  Dialog,
  TextBox,
  ImagePicker,
} from "@nutui/nutui";

const list = [
  Tabbar,
  // Swiper,
  Icon,
  Popup,
  Button,
  NavBar,
  Cell,
  TextInput,
  Uploader,
  SearchBar,
  Scroller,
  Infiniteloading,
  Toast,
  Slider,
  Dialog,
  TextBox,
  ImagePicker,
];

list.forEach((item) => {
  item.install(Vue);
});

// Tabbar.install(Vue);
// Swiper.install(Vue);

import { skeleton } from '@nutui/nutui';
skeleton.skeleton.install(Vue);
skeleton.skeletonCircle.install(Vue);
skeleton.skeletonColumn.install(Vue);
skeleton.skeletonRow.install(Vue);
skeleton.skeletonSquare.install(Vue);

import Picker from "@nutui/nutui/dist/packages/picker/picker.js";
import "@nutui/nutui/dist/packages/picker/picker.css";
Picker.install(Vue);

import Swiper  from "@nutui/nutui/dist/packages/swiper/swiper";
import "@nutui/nutui/dist/packages/swiper/swiper.css";

Swiper.install(Vue)