import axios from "@/utils/request";

/**
 * banner
 * @param {*} config
 * @returns
 */
export function fetchBanner(config) {
  return axios({
    url: "/common/banner",
    method: "post",
    isToken: true,
    ...config,
  });
}

export function fetchToplist(config) {
  return axios({
    url: "/common/toplist",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 * 精选图书
 * @param {*} config
 * @returns
 */
export function fetchSiftBooks(config) {
  return axios({
    url: "/common/toplist",
    method: "post",
    data: {
      menu: "book",
      ltype: "sellist",
    },
    isToken: true,
    ...config,
  });
}

// 精选期刊
export function fetchSiftJournal(config) {
  return axios({
    url: "/common/toplist",
    method: "post",
    data: {
      menu: "journal",
      ltype: "sellist",
    },
    isToken: true,
    ...config,
  });
}

// 精选报纸
export function fetchSiftNewspaper(config) {
  return axios({
    url: "/common/toplist",
    method: "post",
    data: {
      menu: "paper",
      ltype: "sellist",
    },
    isToken: true,
    ...config,
  });
}

// 精选听书
export function fetchSiftLbook(config) {
  return axios({
    url: "/common/toplist",
    method: "post",
    data: {
      menu: "lbook",
      ltype: "sellist",
    },
    isToken: true,
    ...config,
  });
}

//

// 热读图书
export function fetchHotBooks(config) {
  return axios({
    url: "/common/toplist",
    method: "post",
    data: {
      menu: "book",
      ltype: "toplist",
    },
    isToken: true,
    ...config,
  });
}

// 热读期刊
export function fetchHotJournal(config) {
  return axios({
    url: "/common/toplist",
    method: "post",
    data: {
      menu: "journal",
      ltype: "toplist",
    },
    isToken: true,
    ...config,
  });
}

// 热读报纸
export function fetchHotNewspaper(config) {
  return axios({
    url: "/common/toplist",
    method: "post",
    data: {
      menu: "paper",
      ltype: "toplist",
    },
    isToken: true,
    ...config,
  });
}

/**
 * 热读听书
 * @param {*} config
 * @returns
 */
export function fetchHotLbook(config) {
  return axios({
    url: "/common/toplist",
    method: "post",
    data: {
      menu: "lbook",
      ltype: "toplist",
    },
    isToken: true,
    ...config,
  });
}

/**
 * 地区列表
 * @param {*} config
 * @returns
 */
export function fetchAreaList(config) {
  return axios({
    url: "/area/index",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 * 获取配置
 * 获取用户所属机构配置的首页配置数据初始化获取（模块、分类）
 * @param config
 * @returns {*}
 */
export function fetchLibConfig(config) {
  return axios({
    url: "/common/webInit",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 * 搜索
 * @param {*} config
 * @returns
 */
export function fetchSearch(config) {
  return axios({
    url: "/common/search",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 * 上传文件
 */
export function fetchUploadFile(config) {
  return axios({
    url: "/common/upload",
    method: "post",
    isToken: true,
    ...config,
  });
}
/**
 * 意见反馈
 * @param {*} config
 * @returns
 */
export function fetchFeedback(config) {
  return axios({
    url: "/common/proposal",
    method: "post",
    isToken: true,
    ...config,
  });
}

// 报纸详情
export function fetchNewspaperData(config) {
  return axios({
    url: "/paper/details",
    method: "post",
    isToken: true,
    ...config,
  });
}

/**
 * e博在线访问统计
 * @param {*} config
 * @returns
 */
export function fetchDataTotal(config) {
  return axios({
    url: "/index/views",
    method: "post",
    isToken: true,
    ...config,
  });
}
