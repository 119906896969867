// 是否刷新
export const RELOAD = "reload";

export const FONT_SIZE = "font-size";

export const THEME = "theme";

export const USER = "user";

export const TOKEN = "ebo_h5-token";
/**
 * 期刊
 */
export const JOURNAL = "journal-data";
/**
 * 听书
 */
export const LBOOK = "lbook-data";

// 用户信息
export const USER_INFO = "userinfo";

// 机构配置信息
export const LIB_CONFIG = "lib-config";

// 图书信息
export const BOOK = "book-data";
